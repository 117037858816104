@import '../../../sharedStyles.scss';
@import '../Stats/Stats.module.scss';

$iconSize: 56px;

.icon {
  width: $iconSize;
  height: $iconSize;
  background-size: contain;
  background-repeat: no-repeat;

  // Do not display the icon for iPhone SEs
  @include se-only {
    display: none;
  }
}

.iconWin {
  @extend .icon;
  background-image: var(--wordle-star);
}

.iconFail {
  @extend .icon;
  background-image: var(--wordle-fail);
}

.mainContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;

  @media (min-width: 500px) {
    max-width: 350px;
    margin: auto;
  }

  h2 {
    margin-bottom: var(--vertical-spacing-2);
  }

  h3 {
    margin-bottom: calc(var(--vertical-spacing-0-5) * 7.5);
    font: $weight-medium 24px/27px $karnak;
  }
}

.archiveStatsWrapper {
  margin-bottom: var(--vertical-spacing-2);
}
