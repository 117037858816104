.container {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: calc(100% - var(--header-height) - 1px);
  left: 0;
  justify-content: center;
  background-color: var(--error-background);
  animation: SlideIn 100ms linear;
  z-index: var(--error-z-index);
}

.errorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  position: relative;
  top: 166px;
  font-family: 'nyt-franklin';
}

.errorText {
  font-family: 'nyt-franklin-400';
  text-align: center;
  margin-top: var(--vertical-spacing-5);
  font-size: 20px;
  line-height: 24px;
  color: var(--color-tone-1);
}

.errorTilesContainer {
  width: 208px;
  display: inline-flex;
  justify-content: space-between;
}

.errorTiles {
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border: 1.27px solid var(--gray-13);
  width: 40px;
  height: 40px;
  box-sizing: border-box;
}

.backButton {
  margin-top: var(--vertical-spacing-3);
  border-radius: 24px;
  width: 150px;
  height: 48px;
  font-family: 'nyt-franklin-600';
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  background-color: var(--color-tone-1);
  box-shadow: none;
  border: none;
  color: var(--color-tone-7);
  cursor: pointer;
}

@keyframes SlideIn {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes SlideOut {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translateY(60px);
  }
}

.errorBannerContainer {
  background-color: #323232;
  color: white;
  font-size: 14px;
  height: auto;
  opacity: 1;
  transition: all 0.5s ease-in-out;
  position: absolute;
  width: 100%;
  left: 0;
  font-family: 'nyt-franklin';
  p {
    font-weight: 400;
    margin: calc(var(--vertical-spacing-0-5) * 1.25)
      calc(var(--horizontal-spacing-0-5) * 1.25)
      calc(var(--vertical-spacing-0-5) * 1.25)
      calc(var(--horizontal-spacing-0-5) * 2.5);
  }
  p.errorTitle {
    font-weight: 700;
  }
  .close {
    color: white;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    cursor: pointer;
    margin: calc(var(--vertical-spacing-0-5) * 1.25)
      calc(var(--horizontal-spacing-0-5) * 1.25) 0px 0px;
  }
  .errorRow {
    display: flex;
    justify-content: space-between;
  }
  &.hideBanner {
    opacity: 0;
    height: 0;
  }
}
