.container {
  margin-top: var(--vertical-spacing-2-5);
  width: 100%;
  max-width: 350px;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.cardEmphasis {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 6px; // TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED
  padding: var(--vertical-spacing-1-5) var(--horizontal-spacing-1-5)
    var(--vertical-spacing-1-5) var(--horizontal-spacing-1);
  border-radius: 4px;
}

.promoIcon {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

a {
  text-decoration: none;
}

.cardText {
  align-self: center;
}

.cardText + .arrow {
  min-width: 20px;
  min-height: 20px;
  background: url('/public/assets/wordle/arrow-right.svg') no-repeat;
  background-size: contain;
  background-position: center;
  margin-left: auto;
}

.cardDescription {
  color: var(--newsGray-100);
  font-size: 16px;
  line-height: 17px;
  font-family: 'nyt-franklin';
  font-weight: 700;
  margin-top: calc(var(--vertical-spacing-0-5) * 0.5);
  display: inline-block;
}

.newLabel {
  color: #0051b7;
  font-family: 'nyt-franklin';
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  margin-left: var(--horizontal-spacing-1);
  letter-spacing: 1px;
}

/* Spelling Bee Card */
.spellingBeeCard {
  background-color: var(--spellingBeeYellow);
}

.spellingBeeIcon {
  background-image: var(--spelling-bee-promo);
  min-width: 45px;
  min-height: 40px;
}

/* Wordle Archive Card */
.wordleArchiveCard {
  background-color: var(--gray-13);
  padding-left: var(--horizontal-spacing-2);
  gap: 12px; // TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED
}

.wordleArchiveIcon {
  min-width: 24px;
  min-height: 24px;
  background-image: var(--wordle-icon);
}
