.wrapper {
  display: flex;
  flex-direction: column;
}

.badgeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20%;

  :global(button.pz-moment__button.secondary.default),
  :global(a.pz-moment__button.secondary.default) {
    color: var(--color-tone-1);
    border: 1px solid var(--color-tone-1);
  }
}
