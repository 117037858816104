@import '../../../shared/scss-helpers/index.scss';

.banner {
  @include flexbox(column);
  background-color: $white;
  min-height: 275px;
  position: sticky;
  bottom: 0;
  width: 100%;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  color: $black;
  margin-top: calc(var(--vertical-spacing-0-5) * 2.5);

  &.relative {
    position: relative;
  }
}

.headline {
  font: $weight-bold rem(36) / rem(38) $karnak-condensed;
  text-align: center;
  padding: 0 calc(var(--horizontal-spacing-0-5) * 8.75);
}

.smallHeadline {
  font: $weight-bold rem(15) / rem(18) $franklin;
  margin-top: calc(var(--vertical-spacing-0-5) * 1.25);
}

.inlineGames {
  background: url('/public/assets/icons/inline-games-all.svg')
    no-repeat;
  width: 280px;
  height: 28px;

  .headline + & {
    margin-top: calc(var(--vertical-spacing-0-5) * 3.75);
  }
}

.playButton {
  @include flexbox;
  font: $weight-medium-bold 1rem $franklin;
  border: solid 1px $black;
  border-radius: 24px;
  padding: calc(var(--vertical-spacing-0-5) * 2.5)
    calc(var(--horizontal-spacing-0-5) * 5.5);

  .inlineGames + & {
    margin-top: var(--vertical-spacing-2-5);
  }

  i {
    width: 27px;
    height: 27px;
    border-radius: 7px;
  }
}

.ctaImage {
  background: url('/public/assets/apps/games-app-screens.png') 0 0 /
    cover no-repeat;
  height: 264px;
  width: 375px;
}

.iconTextWrapper {
  @include flexbox(column);

  & + i {
    position: absolute;
    right: 10px;
  }
}

.imageOverlay {
  @include flexbox(column);
  min-height: 94px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $white;
}
