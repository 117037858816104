.page {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  background-color: var(--color-background);
  animation: SlideIn 100ms linear;
  z-index: var(--page-z-index);

  &.closing {
    animation: SlideOut 150ms linear;
  }

  header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  h1 {
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: calc(var(--vertical-spacing-0-5) * 2.5);
  }
}

.pageNew {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  top: 0;
  left: 0;
  justify-content: center;
  background-color: var(--color-background);
  animation: SlideIn 100ms linear;
  z-index: var(--page-z-index);
  overflow-y: auto;
  display: flex;
  .gamesIcon {
    width: 95px;
    height: 18px;
  }
  .close {
    margin-right: calc(var(--horizontal-spacing-0-5) * 7.5);
  }
  &.closing {
    animation: SlideOut 150ms linear;
  }
  &.noDarkMode {
    background-color: var(--white);
    color: black;
    > .content {
      color: black;
    }
  }
}
.pageFixed {
  position: fixed;
}
.headerNew {
  padding: calc(var(--vertical-spacing-0-5) * 3.75) 0px;
  > p {
    text-align: center;
    margin: calc(var(--vertical-spacing-0-5) * 2.5) 0px;
  }
}

.content {
  position: relative;
  color: var(--color-tone-1);
  padding: 0 var(--horizontal-spacing-4);
  max-width: var(--game-max-width);
  width: 100%;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.contentContainer {
  height: 100%;
}

.close {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: absolute;
  right: 0;
  user-select: none;
  cursor: pointer;
  margin: 0;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .content {
    max-width: 100%;
    padding: 0;
  }

  .close {
    padding: 0 var(--horizontal-spacing-2);
  }
}

@keyframes SlideIn {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes SlideOut {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translateY(60px);
  }
}
