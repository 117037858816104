@import '@nyt/playbook/lib/assets/palette/index.css';
@import 'shared/scss-helpers/mixins.scss';
@import 'shared/scss-helpers/colors.scss';
@import '../../../sharedStyles.scss';

// variables ---------------------------------------------
// Default max width for Regiwall stypes
$maxWidth: 520px;

.gameStats {
  font-family: 'nyt-franklin';
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto auto auto;
  padding-top: var(--vertical-spacing-2);
  max-width: $maxWidth;

  @media (min-width: 500px) {
    padding-top: calc(var(--vertical-spacing-0-5) * 12.5);
    align-self: center;
  }

  .statsContainer {
    align-self: center;

    @media (min-width: 500px) {
      width: 343px;
    }
  }
}

.statisticsHeading {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin: 0;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: calc(var(--vertical-spacing-0-5) * 2.5);
  letter-spacing: 0.05em;

  @media (min-width: 500px) {
    padding-top: 2var (--vertical-spacing-2-5);
    width: 250px;
    margin-left: calc(var(--horizontal-spacing-0-5) * 5.5);
  }

  @media (min-width: 1000px) {
    padding-top: 0px;
  }
}

.statistics {
  display: flex;
  width: 100%;
  margin-bottom: calc(var(--vertical-spacing-0-5) * 2.5);

  @media (min-width: 500px) {
    align-self: center;
  }
}

.statisticContainer {
  font-family: 'nyt-franklin';
  flex: 1;
  margin-left: var(--horizontal-spacing-1-5);
  &:last-child {
    margin-right: var(--horizontal-spacing-1-5);
  }
}

.statisticContainer .statistic {
  font-size: 26px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.05em;
  font-variant-numeric: proportional-nums;
  line-height: 34px;

  &:not(.small) {
    @media (min-width: 500px) {
      font-size: 44px;
      font-weight: 500;
    }
  }
}

.statistic.timer {
  font-variant-numeric: initial;
  font-size: 32px;
  line-height: 34px;
  letter-spacing: 0.025em;
}

.statisticContainer .label {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 16px;
  letter-spacing: 0.1em;

  @media (min-width: 500px) {
    margin-top: var(--vertical-spacing-0-5);
  }
}

.guessDistribution {
  width: 80%;
  margin-bottom: calc(var(--vertical-spacing-0-5) * 2.5);

  @media (min-width: 500px) {
    width: 58%;
    margin-bottom: var(--vertical-spacing-2-5);
    margin-left: calc(var(--horizontal-spacing-0-5) * 5.5);
  }
}

.graphContainer {
  width: 100%;
  height: 18px;
  display: flex;
  align-items: center;
  padding-bottom: var(--vertical-spacing-0-5);
  font-size: 14px;
  line-height: 20px;
}

.graphContainer .graph {
  width: 100%;
  height: 100%;
  padding-left: var(--horizontal-spacing-0-5);
}

.graphContainer .graph .graphBar {
  height: 100%;
  /* Assume no wins */
  width: 0%;
  position: relative;
  background-color: var(--color-absent);
  display: flex;
  justify-content: center;
}

.graphContainer .graph .graphBar.highlight {
  background-color: var(--color-correct-high-contrast);
}

.graphContainer .graph .graphBar.alignRight {
  justify-content: flex-end;
  padding-right: var(--horizontal-spacing-1);
}

.graphContainer .graph .numGuesses {
  font-weight: bold;
  color: var(--tile-text-color);
  font-size: 12px;
  line-height: 18px;
}

.footer {
  display: flex;
  width: 100%;
}

.countdown {
  border-right: 1px solid var(--color-tone-1);
  padding-right: var(--horizontal-spacing-1-5);
  width: 50%;
}

.share {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: var(--horizontal-spacing-1-5);
  width: 50%;
}

.noData {
  margin-top: var(--vertical-spacing-0-5);
  margin-bottom: calc(var(--vertical-spacing-0-5) * 2.5);
  font-size: 14px;
}

.shareButton {
  background-color: var(--key-bg-correct);
  color: var(--key-evaluated-text-color);
  font-family: inherit;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-transform: uppercase;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
  width: 80%;
  font-size: 20px;
  height: 52px;
  -webkit-filter: brightness(100%);
  filter: brightness(100%);
}

@media (max-width: 444px) {
  .shareButton {
    font-size: 16px;
  }
}

.shareButton:hover {
  opacity: 0.9;
}

.shareIconContainer {
  width: 24px;
  height: 24px;
  padding-left: var(--horizontal-spacing-1);
}

.statsBtnLeft {
  justify-content: unset;
  width: 80%;
  margin: 0px 0px calc(var(--vertical-spacing-0-5) * 2.5) 0;
  @media (min-width: 500px) {
    width: 250px;
    margin-left: calc(var(--horizontal-spacing-0-5) * 5.5);
  }

  h1 {
    display: inline-block;
  }

  .guessDistributionCopy {
    font-family: 'nyt-franklin';
    font-weight: bold;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
  }

  button {
    margin-left: calc(var(--horizontal-spacing-0-5) * 2.5);
  }
}

.statsBtnCenter {
  margin: 10px; // TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED
}

.statsInfoBtn {
  display: block;
  text-decoration: underline;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: var(--vertical-spacing-2-5);
  color: var(--color-tone-10);
}

.promoButton {
  border: 1px solid var(--color-tone-1);
  padding: 10px; // TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED
  border-radius: 105px;
  background: var(--color-tone-7);
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: inherit;
  text-decoration: none;
  width: 150px;
  display: flex;
  width: 66%;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}

.promoIcon {
  width: 25px;
  height: 25px;
  background-image: var(--spelling-bee-promo);
  background-size: 25px;
  background-position: center;
  margin-left: calc(var(--horizontal-spacing-0-5) * 1.25);
}

.promoButtonContainer {
  border-top: 1px solid var(--color-tone-9);
  width: 100%;
  justify-content: center;
  display: flex;
  padding-top: var(--vertical-spacing-2-5);
}

.ctaContainer {
  width: 100%;
}

.guess {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1em;
}

/*----------------
  A/B TEST STYLES
------------------*/
.testGameStats {
  &_regiwall {
    padding-top: 0;
  }
}

.testTimer {
  font-family: 'nyt-franklin';
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

.loadingContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 300px;
  height: 62px;
  padding: 0 calc(var(--horizontal-spacing-0-5) * 1.5);
  margin-bottom: calc(var(--vertical-spacing-0-5) * 2.5);

  @media (min-width: 500px) {
    height: 66px;
    margin-left: var(--horizontal-spacing-1-5);
  }
}

.skeletonItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  gap: 12px; // TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED
}

.statSkeleton {
  width: 32px;
  height: 32px;
  background-color: var(--color-tone-13);
}

.statNameSkeleton {
  height: 11px;
  width: 60px;
  background-color: var(--color-tone-13);
}

.errorMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font: $weight-medium 12px/14px nyt-franklin;
  background-color: var(--color-tone-7);
  padding: var(--vertical-spacing-1)
    calc(var(--horizontal-spacing-0-5) * 2.5);
  width: calc(100% - 22px);
}

.alertIcon {
  min-height: 16px;
  min-width: 16px;
  margin-right: calc(var(--horizontal-spacing-0-5) * 1.25);
  background: var(--alert-icon) center no-repeat;
}

/*----------------
  BADGES
------------------*/

.badgesContainer {
  display: flex;
  justify-content: center;
  padding-top: var(--vertical-spacing-1-5);
  border-top: 1px solid var(--color-tone-3);
  border-bottom: 1px solid var(--color-tone-3);
  max-width: 350px;
  display: flex;
  justify-content: center;

  // archive badges do not need these styles, just daily wordle
  &.dailyWordle {
    border-bottom: 1px solid var(--color-tone-3);
    margin: var(--vertical-spacing-2-5) 0;
  }
}
