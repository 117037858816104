.boardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
}

.board {
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 5px; // TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED
  padding: 10px; // TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED
  box-sizing: border-box;
}
