.ctaContainer {
  padding: 40px; // TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED

  @media (max-width: 500px) {
    padding: 24px; // TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED
  }
}

.title {
  font-family: 'nyt-franklin-600';
  font-size: 24px;
  line-height: 1.2;
}

.imageModalContainer {
  .title {
    font-size: 24px;
  }
}

.description {
  font-family: 'nyt-franklin-500';
  font-size: 16px;
  line-height: 1.2;
  margin-top: var(--vertical-spacing-1);

  svg {
    position: relative;
    top: 3px;
    left: 1px;
  }
}

.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  border: 1px solid;
  border-radius: 16px;
  padding: calc(var(--vertical-spacing-0-5) * 1.5)
    calc(var(--horizontal-spacing-0-5) * 4.25)
    calc(var(--vertical-spacing-0-5) * 1.75);
  background-color: var(--color-tone-1);
  color: var(--color-tone-7);
  border-color: var(--color-tone-7);
  margin-top: var(--vertical-spacing-2);

  svg {
    width: 12px;
    height: 12px;
    margin: calc(var(--vertical-spacing-0-5) * 0.25) 0 0
      calc(var(--horizontal-spacing-0-5) * 1.25);
    display: inline-block;
  }
}

.imageModalContainer {
  img,
  video {
    height: auto;
    max-width: 100%;
  }
}

@media (max-width: 500px) {
  .title {
    font-size: 18px;
    margin-top: var(--vertical-spacing-2);
  }

  .imageModalContainer .title {
    font-size: 18px;
    margin-top: 0;
  }

  .description {
    font-size: 14px;
  }

  .button {
    margin-top: var(--vertical-spacing-1);
  }
}
