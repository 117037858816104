@import '../../../sharedStyles.scss';
@import '../../../../../shared/scss-helpers/mixins.scss';

// variables ---------------------------------------------
$iconSize: 56px;
$maxWidth: 520px;

.parent {
  max-width: $maxWidth;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.icon {
  width: $iconSize;
  height: $iconSize;
  margin-bottom: unset !important;
  background-image: var(--wordle-icon);
  background-size: cover;
  background-repeat: no-repeat;
}

.cta {
  letter-spacing: 0;
  text-transform: none;
  text-align: center;
  display: flex;
  flex-direction: column;

  .heading {
    @extend .regiwallText_heading_condensed;
    width: $textWidth_loginForm_heading-mobile;

    // iPad styles
    @media (min-width: $ipadMinWidth) and (max-width: $ipadMaxWidth) {
      width: $textWidth_loginForm_heading-ipad;
    }

    // Desktop styles
    @media (min-width: $ipadMaxWidth) {
      width: $textWidth_loginForm_heading-desktop;
    }
  }

  .subheading {
    font: 300 20px 'nyt-franklin';
    line-height: 120%;
    width: $textWidth_loginForm_subheading-mobile;

    // iPad styles
    @media (min-width: $ipadMinWidth) and (max-width: $ipadMaxWidth) {
      font-size: 24px;
      width: $textWidth_loginForm_subheading-ipad;
    }

    // Desktop styles
    @media (min-width: $ipadMaxWidth) {
      font-size: 24px;
      width: $textWidth_loginForm_subheading-desktop;
    }
  }
}

.loginForm {
  background-color: white !important;
  margin: 0 calc(var(--horizontal-spacing-0-5) * -4);
}
