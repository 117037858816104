@import '../../../../../../shared/scss-helpers/index.scss';

.messageContainer {
  display: flex;
  flex-direction: column;
  border: 0.5px solid var(--color-tone-1);
  border-radius: 3px;
  color: var(--color-tone-1);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  padding: 20px; // TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED
  margin-bottom: calc(var(--vertical-spacing-0-5) * 7.5);
}

.cutoverMessage {
  font: $weight-medium 14px/18px nyt-franklin;
  margin-bottom: var(--vertical-spacing-2-5);

  strong {
    display: block;
    font-weight: $weight-bold;
    margin-bottom: calc(var(--vertical-spacing-0-5) * 1.5);
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px; // TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED

  button,
  a {
    font: $weight-medium-bold 14px/18px nyt-franklin;
    width: fit-content;
    border: none;
    border-radius: 24px;
    padding: calc(var(--vertical-spacing-0-5) * 1.5)
      var(--horizontal-spacing-2);

    color: var(--color-tone-1);
    background-color: var(--color-tone-7);
    border: 1px solid var(--color-tone-1);

    &.filled {
      border: 1px solid var(--color-tone-1);
      background-color: var(--color-tone-1);
      color: var(--color-tone-7);
    }
  }
}

.newLabel {
  color: var(--inline-links);
  font: $weight-bold 10px/10px nyt-franklin;
  margin-bottom: calc(var(--vertical-spacing-0-5) * 1.5);
}
