@use '../../../../../shared/scss-helpers/fonts.scss';
@use '../../../../../shared/scss-helpers/spacing.scss';
@import '../../../sharedStyles.scss';
@import '../../../../../shared/scss-helpers/index.scss';

$contentWidth: 312px;

// component styles --------------------------------------
.parentContainer {
  width: $contentWidth;
  display: flex;
  flex-direction: column;
}

.regiButton {
  @include button-styles;
  font-weight: $weight-medium-bold;
  color: var(--color-tone-7);
  background-color: var(--color-tone-1);
  margin: var(--vertical-spacing-2-5) 0 var(--vertical-spacing-2-5) 0;

  &Container {
    @include button-container-styles;
  }
}

.loginButton {
  background: none;
  border: none;
  cursor: pointer;
}

.bot {
  margin-top: var(--vertical-spacing-5);
  width: 110%;
}
