.container {
  border-top: 1px solid var(--color-tone-9);
  text-align: left;
  display: flex;
  padding: calc(var(--vertical-spacing-0-5) * 2.5)
    calc(var(--horizontal-spacing-0-5) * 2.5)
    calc(var(--vertical-spacing-0-5) * 1.75)
    calc(var(--horizontal-spacing-0-5) * 3.5);
}

@media (min-width: 500px) {
  .container {
    padding: calc(var(--vertical-spacing-0-5) * 3.75)
      calc(var(--horizontal-spacing-0-5) * 12.5)
      calc(var(--vertical-spacing-0-5) * 2.5)
      calc(var(--horizontal-spacing-0-5) * 12.5);
  }
}

.icon {
  padding-top: calc(var(--vertical-spacing-0-5) * 2.5);
}
.promoIcon {
  width: 25px;
  height: 25px;
  background-image: var(--spelling-bee-promo);
  background-size: 25px;
  background-position: center;
  margin-left: calc(var(--horizontal-spacing-0-5) * 1.25);
}
.textContainer {
  > p {
    margin: 5px; //TODO NONDIRECTIONAL SPACING TOKEN REQUIRED
  }
  .bold {
    color: var(--color-tone-1);
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
  .subText {
    color: var(--color-tone-11);
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: var(--vertical-spacing-1-5);
  }
  .title {
    color: var(--outlineBlue);
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-bottom: calc(var(--vertical-spacing-0-5) * 0.5);
  }

  .line1 {
    margin-bottom: 0;
  }

  .line2 {
    margin-top: 0;
    margin-bottom: var(--vertical-spacing-0-5);
  }
}

.buttonsContainer {
  text-align: center;
  margin-top: calc(var(--vertical-spacing-0-5) * 2.5);

  .moreLink {
    all: unset;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: var(--color-tone-11);
    margin: calc(var(--vertical-spacing-0-5) * 2.5)
      var(--horizontal-spacing-2-5) var(--vertical-spacing-2-5)
      var(--horizontal-spacing-2-5);
    cursor: pointer;
  }
  .loginButton {
    background-color: var(--color-tone-7);
    color: var(--color-tone-1);
    cursor: pointer;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    border: 1px solid var(--color-tone-1);
    border-radius: 55px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.04em;
    text-decoration: none;
    padding: calc(var(--vertical-spacing-0-5) * 3.5)
      calc(var(--horizontal-spacing-0-5) * 1.25);
    > a {
      color: inherit;
      text-decoration: none;
    }
  }
}

@media (min-width: 500px) {
  .buttonsContainer {
    width: 80%;
    margin: 0 auto;
  }
  .loginButton {
    font-size: 14px;
    width: 90%;
    margin: 0 auto;
  }
}

@media (max-width: 499px) {
  .buttonsContainer {
    width: 70%;
    margin: 0 auto;
  }
  .loginButton {
    width: 100%;
  }
}

.promoButton {
  border: 1px solid var(--color-tone-1);
  padding: 10px; // TODO NONDIRECTIONAL SPACING TOKEN REQUIRED
  border-radius: 105px;
  background: var(--color-tone-7);
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-right: var(--horizontal-spacing-1-5);
  vertical-align: middle;
  justify-content: center;
}

.shareButton {
  background-color: var(--color-correct-high-contrast);
  color: var(--key-evaluated-text-color);
  border-radius: 104px;
  border: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  vertical-align: middle;
  cursor: pointer;
}

#shareIcon {
  vertical-align: middle;
  margin-left: calc(var(--horizontal-spacing-0-5) * 2.5);
}

.shareText {
  color: var(--key-evaluated-text-color);
}

@media (max-width: 295px) {
  .promoIcon {
    width: 15px;
    height: 15px;
    background-size: 15px;
  }
  .shareButton {
    font-size: 10px;
  }
}

.bottomSheet {
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--color-tone-7);

  .bottomSheetContainer {
    padding: 16px; // TODO NONDIRECTIONAL SPACING TOKEN REQUIRED
    padding-bottom: 0;
  }

  .shareButton {
    width: 200px;
    height: 44px;
    min-width: fit-content;
    padding: 0 1rem;
    margin-bottom: var(--vertical-spacing-4);

    @media (max-width: 500px) {
      width: 230px;
      height: 44px;
    }
  }

  .archive {
    padding-bottom: 0;
  }

  .shareText {
    font-family: 'nyt-franklin';
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.04em;
  }

  .nextWordle {
    font-family: 'nyt-franklin';
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  }
}

.sbButtonFooter {
  .bottomSheetContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
  }

  .shareText,
  .sbPlayButton {
    font: 700 13px 'nyt-franklin';
  }

  .mediumText {
    display: none;
  }

  .nextWordle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.07em;
    margin-bottom: var(--vertical-spacing-1-5);

    span {
      margin-right: var(--horizontal-spacing-0-5);
    }
  }

  .sbPlayButton {
    @extend .promoButton;
    border: 0;
    background-color: var(--spellingBeeYellow);

    span {
      color: #121212;
      letter-spacing: normal;
    }
  }

  .promoIcon {
    width: 16px;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  @media (min-width: 321px) {
    .sbPlayButton {
      font-size: 14px;
      line-height: 18px;
    }

    .shareText {
      font: 600 16px/20px 'nyt-franklin';
    }

    .mediumText {
      display: block;
    }
  }
}
