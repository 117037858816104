@import 'shared/scss-helpers/mixins.scss';

.container {
  border-top: 1px solid var(--color-tone-9);
  text-align: left;
  display: flex;
  padding-top: var(--vertical-spacing-2);
}

@media (min-width: 500px) {
  .container {
    padding-bottom: calc(var(--vertical-spacing-0-5) * 2.5);
    margin: auto;
    width: 343px;
    border-bottom: 1px solid var(--color-tone-9);
  }
}

.icon {
  content: var(--stats-auth);
  padding-right: calc(var(--horizontal-spacing-0-5) * 0.5);
  height: 31px;
  width: 31px;
  margin-right: var(--horizontal-spacing-1-5);
}

.buttonsContainer {
  .loginButton {
    padding: 0px;
    text-align: left;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: var(--color-tone-1);
    background-color: transparent;
    border: none;
    cursor: pointer;
    > a {
      color: inherit;
    }

    @media (min-width: 500px) {
      width: 343px;
    }
  }
}
