@import '../../../../../shared/scss-helpers/index.scss';

$container-padding: '1rem';

.buttonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(var(--keyboard-height) - #{$container-padding});
  min-height: calc(var(--keyboard-height) - #{$container-padding});
  gap: 0.75rem;
  padding: 0 1rem 1rem;
  margin: auto;
  max-width: 252px;
  width: 100%;
  min-width: fit-content; // Allows buttons to grow if font-size is increased
}

.archiveAdmireButton {
  display: flex;
  justify-content: center;
  height: 44px;
  border-radius: 24px;
  font: $weight-medium-bold 16px/18px $franklin;
  color: var(--color-tone-8);
  border: solid 1px var(--color-tone-8);
  background-color: var(--color-tone-7);
  white-space: nowrap;
  padding: 0 calc(var(--horizontal-spacing-0-5) * 1.5);
}
