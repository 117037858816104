@import 'shared/scss-helpers/mixins.scss';
@import '../../../../sharedStyles.scss';

// variables ---------------------------------------------
$iconSize: 56px;

.icon {
  width: $iconSize;
  height: $iconSize;
  margin-bottom: unset !important;

  &_wordle {
    background-image: var(--wordle-icon);
    background-size: cover;
    background-repeat: no-repeat;

    // take out the icon for iPhone SEs
    @include se-only {
      display: none;
    }
  }

  &_congrats {
    background-image: url('/public/assets/wordle/wordle_congrats.svg');

    // take out the icon for iPhone SEs
    @include se-only {
      display: none;
    }
  }

  &_fail {
    background-image: var(--wordle-fail);
    background-size: cover;
    background-repeat: no-repeat;

    // take out the icon for iPhone SEs
    @include se-only {
      display: none;
    }
  }

  &_hidden {
    display: none;
  }
}

.statsHeading {
  width: 100%;
  text-align: left;
  margin-bottom: var(--vertical-spacing-3);
  font-size: 28px;
}
