.help {
  width: 100%;
  margin: auto;
}

.subheading {
  font-family: 'nyt-karnak';
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

.instructions {
  font-family: 'nyt-franklin';
  font-size: 16px;
  line-height: 20px;
  list-style-type: disc;
  font-weight: 500;
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding-inline-start: 20px; // TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED
  color: var(--color-tone-1);

  li {
    margin-bottom: var(--vertical-spacing-0-5);
  }

  li::marker {
    font-size: 18px;
  }
}

.examples {
  font-family: 'nyt-franklin';

  strong {
    font-weight: bold;
  }

  p,
  h3 {
    margin: 0;
    font-size: 16px;
    line-height: 20px;

    &.bold {
      font-weight: bold;
    }
  }
}

.example {
  margin-top: var(--vertical-spacing-1);
  margin-bottom: var(--vertical-spacing-2-5);

  p {
    font-size: 16px;
    line-height: 20px;
    margin-top: var(--vertical-spacing-1);
  }
}

.tileContainer {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-right: var(--horizontal-spacing-0-5);
}

.reminderSignUp {
  font-family: 'nyt-franklin';
  font-size: 16px;
  line-height: 20px;
  margin-top: var(--vertical-spacing-2-5);

  a {
    color: var(--inline-links);
    text-decoration: underline var(--inline-links);
  }
}

/*---auth---*/
.statsLogin {
  font-family: 'nyt-franklin';
  position: relative;
  color: var(--color-tone-1);
  font-size: 16px;
  line-height: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  padding: calc(var(--vertical-spacing-0-5) * 4.25) 0;
  border-top: 1px solid var(--gray-3);
  border-bottom: 1px solid var(--gray-3);
  margin: 0 auto var(--vertical-spacing-2-5);

  button {
    border: none;
    background-color: transparent;
    font-family: 'nyt-franklin';
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    padding: 0px 0px calc(var(--vertical-spacing-0-5) * 0.75);
  }

  a,
  button {
    color: var(--inline-links);
    text-decoration: underline var(--inline-links);
  }

  .loginArrow {
    text-decoration: none;
    justify-self: flex-end;
  }

  .loginText {
    flex-grow: 2;
  }

  .statsIcon {
    margin-right: calc(var(--horizontal-spacing-0-5) * 2.5);
  }
}

@media (max-width: 295px) {
  .tileContainer {
    height: 32px;
  }
}
