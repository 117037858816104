.title {
  font-family: 'nyt-franklin';
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: calc(var(--horizontal-spacing-0-5) * 2.5);
  color: var(--color-tone-1);
}

.containerLink {
  padding: 30px; // TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED
  p:last-child {
    font-weight: 700;
  }
}

.containerProblems {
  padding: 30px; // TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED
}

.containerLink,
.containerProblems {
  padding-top: calc(var(--vertical-spacing-0-5) * 3.5);
}

.explainerFooterText {
  margin: 30px; // TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED
  padding-top: calc(var(--vertical-spacing-0-5) * 7.5);
  border-top: 1px solid var(--gray-4);
  font-size: 15px;
  line-height: 130%;
}

.text {
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.01em;
  text-align: left;
  margin-left: calc(var(--horizontal-spacing-0-5) * 2.5);
  color: var(--color-tone-1);
  > a {
    color: var(--inline-links);
    text-decoration: underline var(--inline-links);
  }
}

.statsLinkContainer {
  margin-top: var(--vertical-spacing-5);
  display: flex;
  flex-direction: column;
}

.actionButton {
  height: 44px;
  border-radius: 104px;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 600;
  line-height: 14px;
  font-size: 14px;
  text-align: center;
  padding: 0 calc(var(--horizontal-spacing-0-5) * 7.5);
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.05em;
  cursor: pointer;

  &:last-child {
    margin-top: calc(var(--vertical-spacing-0-5) * 3.5);
  }
}

.loginButton {
  @extend .actionButton;
  background: var(--color-tone-1);
  color: var(--color-tone-7);
}

.helpButton {
  @extend .actionButton;
  background: var(--color-tone-7);
  color: var(--color-tone-1);
  border: 1px solid var(--color-tone-1);
}

.gamesIcon {
  width: 95px;
  height: 18px;
}

.headerNew {
  padding: calc(var(--vertical-spacing-0-5) * 3.75) 0px;
  > p {
    text-align: center;
    margin: calc(var(--vertical-spacing-0-5) * 2.5) 0px;
  }
}

.close {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: absolute;
  right: 0;
  user-select: none;
  cursor: pointer;
  margin: 0;
  margin-right: calc(var(--horizontal-spacing-0-5) * 7.5);
}
